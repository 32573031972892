.skills-container {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6.25em;
}

.skills-container h1 {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-size: 2em;
}

.skills-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: white;
    margin: 3em 0 10em;
    width: 100%;
    min-height: 100vh;
    border-radius: .5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.skill-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 4em;
    gap: 2em;
}

.skills {
    font-size: 1rem;
    margin-top: 4em;
}

.skill {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    align-items: center;
}

.skill-icon {
    font-size: 2.2857142857142856em;
}

.skill-header-icon {
    font-size: 2rem;
    border-radius: 50%;
    /* border: 4px solid #F2C335; */
    padding: .35em;
}

#backend {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

#backend .skills .skill .skill-icon {
    margin-right: 1em;
}

#frameworks .skills .skill .skill-icon {
    margin-left: 1.5em;
}

@media screen and (max-width: 600px) {
    .skills-container {
        padding: 0 1.5em;
    }
    .skills-section {
        display: flex;
        flex-direction: column;
    }

    .skill-container {
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid black;
    }

    .skill-container:last-child {
        border-bottom: 0;
    }

    #frameworks .skills .skill .skill-icon {
        margin-left: 0;
        margin-right: 1em;
    }

    .skills {
        margin: 0;
    }
}
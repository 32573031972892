.footer-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: -20%;
    padding: 2em 6.25em;
    background-color: #2B76E6;
    color: white;
    margin: 0;
}

.footer-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-element p, .footer-element a{
    color: white;
} 

.footer-element h2 {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
}

.footer-navigation {
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-size: 1.25em;
}

.footer-social-media {
    display: flex;
    gap: 2em;
    font-size: 1.5em;
}

@media screen and (max-width: 600px) {
    .footer-navigation, .footer-element {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-social-media {

    }
}
#about-section {
    background: linear-gradient(#7EDDF2 6.24%, #41AEF2 90.73%);
    width: 100%;
    margin-top: 15em;
    padding: 5em 0;
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    gap: 4em;
    z-index: -3;
}

.about-image {
    height: 420px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.about-image-container {
    margin-left: 6.25em;
    z-index: 1;
}

.about-text-container {
    margin-left: 10rem;
    margin-right: 6.25em;
    z-index: 1;
}

.about-text-container h1{
    font-size: 2em;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
}

.about-text-container p {
    font-size: 1.25em;
}

.wave {
    width: 100%;
}

.about-ball {
    z-index: 0;
}

div[data-number="about-1"] {
    position: absolute;
    bottom: 3em;
    left: 45%;
    transform: scale(75%);
    animation: ball-scale-1 2s ease-in-out infinite alternate;
}

div[data-number="about-2"] {
    position: absolute;
    top: 5em;
    left: 40%;
    transform: scale(120%);
    animation: ball-scale-2 3s ease-in-out infinite alternate;
}

div[data-number="about-3"] {
    position: absolute;
    top: 10em;
    right: 15em;
    transform: scale(80%);
    animation: ball-scale-3 1s ease-in-out infinite alternate;
}

@media screen and (max-width: 600px) {
    #about-section {
        flex-direction: column;
    }

    .about-image {
        height: 256px;
    }

    .about-image-container, .about-text-container {
        margin: 0;
    }

    .about-text-container {
        padding: 1em .5em;
    } 
}
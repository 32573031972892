.header-container {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 10;
}

.header-text {
    font-size: 2em;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    letter-spacing: -2px;
    margin-left: 2em;
}

.header-section, .nav-links {
    display: flex;
    gap: 1em;
    align-items: center;
}

.header-section > a {
    font-size: 2em;
}

.header-section > a:last-child {
    margin-right: 2em;
}

.nav-links {
    font-size: 1.5em;
    margin-right: 2em;
    gap: 3em;
}

.nav-links a {
    transition: transform 100ms ease-out;
}

.nav-links a:hover {
    transform: translateY(-3px);
    transition: transform 250ms ease-in;
}

.hamburger-menu {
    display: none;
    position: absolute;
    right: 1em;
    margin-top: 6%;
    border: none;
    font-size: 1.5em;
    background-color: white;
}

.social-media-links {
    gap: 2em;
}

.social-media-links a {
    transition: transform 100ms ease-out;
}

.social-media-links a:hover {
    transform: scale(110%);
    transition: transform 250ms ease-in;
}

@media screen and (max-width: 600px) {
    .header-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        padding-left: 1em;
    }

    .header-text {
        font-size: 2em;
        margin-left: 0;
    }
    
    .header-section > a:last-child {
        margin: 0;
    }

    #header-section {
        display: none;
        flex-direction: column;
        align-self: center;
    }

    .nav-links {
        margin: 1em 0;
        flex-direction: column;
    }

    .hamburger-menu {
        display: block;
    }
    
    .social-media-links {
        gap: 5em;
        margin: 1em 0;
        padding: 0;
    }
}
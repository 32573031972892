.banner-container {
    position: relative;
    display: flex;
    margin: 20vh 10% 5.25em;
    justify-content: space-between;
}

.banner-greeting {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-size: 2.25em;
}

.banner-description {
    font-size: 1.25em;
}

.banner-btn {
    font-weight: bold;
    text-align: center;
    width: 6em;
    margin: 1em 0;
    background: linear-gradient(180deg, #7EDDF2 -39.13%, #41AEF2 126.09%, #41AEF2 126.09%);
}

.banner-image {
    width: 24em;
}

.banner-nav-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2em;
    font-size: 1.25em;
    padding: .75em;
    animation: nav-arrow-move 500ms ease-in-out infinite alternate;
}

div[data-number="banner-1"] {
    top: -2em;
    left: -3em;
    transform: scale(75%);
    animation: ball-scale-1 2s ease-in-out infinite alternate;
}

div[data-number="banner-2"] {
    top: 1.5em;
    left: 23em;
    transform: scale(120%);
    animation: ball-scale-2 3s ease-in-out infinite alternate;
}

div[data-number="banner-3"] {
    top: 10em;
    left: 15em;
    transform: scale(80%);
    animation: ball-scale-3 1s ease-in-out infinite alternate;
}

@keyframes nav-arrow-move {
    0% {
        transform: translateY(0) translateX(-50%);
    }

    100% {
        transform: translateY(-1em) translateX(-50%);
    }
}

@media screen and (max-width: 600px) {
    .banner-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        margin: .5em 1em;
    }

    .banner-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
    }

    .banner-image {
        width: 256px;
    }

    div[data-number="banner-1"] {
        top: -2em;
        left: -3em;
        transform: scale(50%);
        animation: ball-scale-1 3s ease-in-out infinite alternate;
    }
    
    div[data-number="banner-2"] {
        top: 1.5em;
        left: 20em;
        transform: scale(90%);
        animation: ball-scale-2 4s ease-in-out infinite alternate;
    }

    div[data-number="banner-3"] {
        top: 10em;
        left: 5em;
        transform: scale(60%);
        animation: ball-scale-3 2s ease-in-out infinite alternate;
    }
}
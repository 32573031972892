.form-element {
    display: flex;
    flex-direction: column;
}

label {
    margin: 0.25em 0;
    font-weight: 700;
    font-size: 1.25rem;
}

textarea {
    resize: none;
}

.input-field, textarea {
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid rgba(0, 0, 0, 0.45);
    border-radius: 10px;

    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color:rgba(0, 0, 0, 0.65);
    font-size: 1em;

    padding: 0.5em;
    width: 130%;
    margin-bottom: 1em;
    transition: all 100ms ease-out;
}

.input-field::placeholder,  .input-field:focus, textarea:focus {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color:rgba(0, 0, 0, 0.90);
    transform: translateY(-2px);
}

.input-field:focus, textarea:focus {
    border: 2px solid rgba(0, 0, 0, 0.75);
    outline: none;
    transition: all 100ms ease-in;
}

input[type="submit"] {
    background: linear-gradient(162.39deg, #F2C335 -8.23%, #F24150 104.56%);
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.25em;
    color: white;

    margin-top: 1em;
    padding: 0.6em 0;
    border: none;
    border-radius: 0.7em;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .input-field, textarea {
        width: 100%;
    }
}
.btn {
    display: block;
    padding: 1em 3em;
    border: none;
    border-radius: 15px;
}

:root {
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
}


.ball {
    background: linear-gradient(217.72deg, #F24150 -4.85%, #F2C335 93.02%);
    width: 100px;
    height: 100px;
    border-radius: 1000%;
    position: absolute;
    z-index: -2;
}

::-webkit-scrollbar {
    width: 0.5em;
    margin: 0.25em;
}

::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 5px;
    transition: background-color 500 ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #999;
    transition: background-color 500ms ease-in-out;
}

.scroll-to-top {
    position: fixed;
    right: 2em;
    bottom: 2em;
    padding: 1em 1.25em;
    border: none;
    border-radius: 50%;
    background-color: #ffffff99;
    cursor: pointer;
}

@keyframes ball-scale-1 {
    0% {
        transform: scale(75%) rotateZ(90);
    }

    100% {
        transform: scale(78%) rotateZ(0);
    }
}

@keyframes ball-scale-2 {
    0% {
        transform: scale(120%) rotateZ(90);
    }

    100% {
        transform: scale(115%) rotateZ(0);
    }
}

@keyframes ball-scale-3 {
    0% {
        transform: scale(80%) rotateZ(90);
    }

    100% {
        transform: scale(77%) rotateZ(0);
    }
}

@media screen and (max-width: 600px) {
    body {
        font-size: 14px;
    } 
}
@import-normalize;

body {
  margin: 0;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: #F2F2F2;
}

a {
  text-decoration: none;
  color: #000;
}

.contact-container {
    margin-top: -20%;
    padding: 15% 0 2em;
    background: linear-gradient(180deg, #7EDDF2 0%, #41AEF2 100%);
    color: white;
}

#contact-section {
    display: flex;
    flex-direction: column;
}

#contact-section > h1 {
    align-self: center;
}

.contact-header {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-size: 2em;
}

.contact-section-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1em 6.25em;
}

.left {
    margin-left: -10em;
}

.divider {
    width: 2px;
    background-color: white;
    border-radius: 1px;
}

.right {
    margin-right: -10em;
}

.socials-container {
    margin-top: 5em;
}

.social-link {
    display: flex;
    align-items: center;
    font-size: 1.25em;
    gap: 1em;
    color: white;
    margin-bottom: 2em;
}

.social-icon {
    font-size: 2em;
}

@media screen and (max-width: 600px) {
    .contact-section-content {
        flex-direction: column;
    }

    .left, .right {
        margin: 0;
    }
}